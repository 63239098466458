import {
  useNavigate
} from "react-router-dom";
import { 
  Authenticator, 
  Grid, 
  useAuthenticator,
  Theme as AmplifyTheme,
  ThemeProvider,
} from '@aws-amplify/ui-react';
import { useTheme } from "@mui/material";

function Auth(){
  const { authStatus, user } = useAuthenticator();

  const navigation = useNavigate()

  const muiTheme = useTheme();

  const theme: AmplifyTheme = {
    name: 'Auth Example Theme',
    tokens: {
      colors: {
        background: {
          primary: {
            value: muiTheme.palette.background.paper
          }
        },
        brand: {
          primary: {
            '80': muiTheme.palette.primary.dark
          },
        },
      }
    },
  };

  if( authStatus !== 'authenticated') {
    return (
      <ThemeProvider theme={theme}>
        <Grid height="90vh" justifyContent="center" alignItems="center">
          <Authenticator signUpAttributes={["email"]}  />
        </Grid>
      </ThemeProvider>
    )
  }

  navigation(`/${user.username}`);
  
  return null;
}

export default Auth;