import {
  createBrowserRouter,
  RouterProvider} from "react-router-dom";
import { ApolloClient, InMemoryCache, createHttpLink, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Amplify } from "aws-amplify";
import { useAuthenticator } from '@aws-amplify/ui-react';

import env from '../config';

import Recipe from '../pages/Recipe';
import RecipeProvider from '../providers/RecipeProvider';
import Profile from '../pages/Profile';
import Root from './Root';
import Auth from "../components/Auth";
import EditRecipe from "../pages/EditRecipe";

Amplify.configure({
  aws_cognito_region: env.COGNITO_REGION,
  aws_user_pools_id: env.COGNITO_POOL_ID,
  aws_user_pools_web_client_id: env.COGNITO_CLIENT_ID,
});

const httpLink = createHttpLink({
  uri: env.GRAPHQL_API
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <div>Not Found</div>,
    children: [
      {
        path: "/",
        element: <Auth />
      },
      {
        path: ":username",
        element: <Profile />,
      },
      {
        path: ":username/:recipeId",
        element: <Recipe />
      },
      {
        path: ":username/:recipeId/edit",
        element: <EditRecipe />
      }
    ]
  },
  {
    path: "/auth",
    element: <Auth />
  }
]);

function App() {

  const { user } = useAuthenticator();
  
  const authLink = setContext((_, { headers }) => {
    const session = user?.getSignInUserSession();
    const token = session?.getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token.getJwtToken()}` : "",
      }
    }
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
  
  return (
    <ApolloProvider client={client}>
      <RecipeProvider>
        <RouterProvider router={router} />
      </RecipeProvider>
    </ApolloProvider>
  );
}

export default App;
