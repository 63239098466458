import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NutritionalInfo as NutritionalInfoType } from '../../gql/graphql';

interface Data {
  name: string; 
  value: number
}

enum Keys {
  "calories" = "calories",
  "fatTotalG" = "fatTotalG",
  "fatSaturatedG" = "fatSaturatedG",
  "proteinG" = "proteinG",
  "sodiumMg" = "sodiumMg",
  "potassiumMg" = "potassiumMg",
  "cholesterolMg" = "cholesterolMg",
  "carbohydratesTotalG" = "carbohydratesTotalG",
  "fiberG" = "fiberG",
  "sugarG" = "sugarG",
}

const names = {
  calories: {
    name: "Calories",
    unit: "cal"
  },
  servingSizeG: {
    name: "Serving size",
    unit: ""
  },
  fatTotalG: {
    name: "Fat",
    unit: "g",
  },
  fatSaturatedG: {
    name: "Saturated Fat",
    unit: "g",
  },
  proteinG: {
    name: "Protein",
    unit: "g",
  },
  sodiumMg: {
    name: "Sodium",
    unit: "g",
  },
  potassiumMg: {
    name: "Potassium",
    unit: "g",
  },
  cholesterolMg: {
    name: "Cholesterol",
    unit: "g",
  },
  carbohydratesTotalG: {
    name: "Carbohydrates",
    unit: "g",
  },
  fiberG: {
    name: "Fiber",
    unit: "g",
  },
  sugarG: {
    name: "Sugar",
    unit: "g",
  },
}

const createData = (nutritionalInfo: NutritionalInfoType): Data[] => {
  return Object.entries(nutritionalInfo).map(([key, val]) => {
    return {
      name: key,
      value: val
    }
  }).filter(data => {
    return Object.keys(Keys).includes(data.name)
  });
}

interface NutritionalInfoProps {
  nutritionalInfo: NutritionalInfoType;
}

export default function NutritionalInfo({
  nutritionalInfo
}: NutritionalInfoProps) {

  const [open, setOpen] = React.useState(false)
  
  const handleClick = () => {
    setOpen(!open)
  }

  const rows = React.useMemo(() => createData(nutritionalInfo), [nutritionalInfo]);

  return (
    <List sx={{ listStyleType: 'disc' }} >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Nutritional Information" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <TableContainer component={Paper}>
          <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">Recipe Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                rows.map(row => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    key={row.name}
                  >
                    <TableCell component="th" scope="row">
                      {names[row.name as Keys].name}
                    </TableCell>
                    <TableCell align="right">{row.value} {names[row.name as Keys].unit}</TableCell>
                  </TableRow>    
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </List>
  );
}