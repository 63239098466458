import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { GetRecipeQuery } from '../../gql/graphql';
import { ListItem } from '@mui/material';

interface IngredientsListProps {
  ingredients: GetRecipeQuery['getRecipe']['ingredients'];
}

export default function NestedList({ ingredients }: IngredientsListProps) {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Ingredients" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            ingredients?.map(ingredient => (
              <ListItem sx={{ p: 0, pl: 4 }}>
                <ListItemText primary={ingredient.name} />
              </ListItem>
            ))
          }
        </List>
      </Collapse>
    </List>
  );
}