import * as React from "react";
import RecipeContext from "./RecipeContext";

interface RecipeProviderProps {
  children: React.ReactNode;
}

const RecipeProvider: React.FC<RecipeProviderProps> = ({ children }) => {

  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  
  return (
    <RecipeContext.Provider      
      value={{
        isOpen,
        toggleIsOpen: () => setIsOpen(!isOpen)
      }}>      
      {children}
    </RecipeContext.Provider>
  )
}

export default RecipeProvider