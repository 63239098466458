import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import RecipeCard from "../../components/RecipeCard";
import { GET_ALL_RECIPES } from './query.graphql';
import { GetAllRecipesQuery, GetAllRecipesQueryVariables } from '../../gql/graphql';
import LoadingScreen from '../../components/LoadingScreen';
import BottomNav from '../../components/BottomNav';

const Profile = () => {

  const { username } = useParams()

  const variables: GetAllRecipesQueryVariables = {
    username
  }
  
  const {data, loading, error } = useQuery<GetAllRecipesQuery>(GET_ALL_RECIPES, {
    variables
  });

  if( error ) {
    throw error;
  }

  if( loading ) {
    return (
      <LoadingScreen />
    );
  }

  return (
    <>
      <Box sx={{ flexGrow: 1, p: 2, pt: 3, mb: 15 }}>
        <Grid container spacing={2}>
          { data?.getAllRecipes?.map((recipe) => (
            <Grid xs={6} md={3} key={recipe.id}>
              <Box sx={{ height: '100%' }}>
                <RecipeCard recipe={recipe} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <BottomNav />
    </>
  )
};

export default Profile;