import { cleanEnv, str, url } from "envalid";

interface Config {
  COGNITO_CLIENT_ID: string;
  COGNITO_REGION: string;
  COGNITO_POOL_ID: string;
  GRAPHQL_API: string;
}

const env = cleanEnv<Config>(process.env, {
  GRAPHQL_API: url({
    devDefault: process.env.REACT_APP_GRAPHQL_URL || "http://localhost:3001/graphql",
    default: process.env.REACT_APP_GRAPHQL_URL
  }),
  COGNITO_CLIENT_ID: str({
    devDefault: "4g559kpqdpsp3adpg7us7gblca",
    default: process.env.REACT_APP_COGNITO_CLIENT_ID
  }),
  COGNITO_REGION: str({
    default: "eu-west-2"
  }),
  COGNITO_POOL_ID: str({
    devDefault: "eu-west-2_szbOprMup",
    default: process.env.REACT_APP_COGNITO_POOL_ID
  }),
});

export default env;