import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Recipe } from '../../gql/graphql';

interface ActionAreaCardProps {
  recipe: Recipe;
}

export default function ActionAreaCard({ recipe }: ActionAreaCardProps) {

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/${recipe.username}/${recipe.id}`);
  }
  
  return (
    <Card sx={{ maxWidth: '345', height: '100%' }}>
      <CardActionArea onClick={handleClick} sx={{height: '100%', display: 'flex', justifyContent: "flex-end", flexDirection: "column", alignItems: "flex-start"}}>
        {recipe?.image && (
          <CardMedia
            component="img"
            height="140"
            image={recipe?.image}
            alt="green iguana"
          />)
        }
        <CardContent sx={{ p: 1 }}>
          <Typography gutterBottom variant="body2" component="div" sx={{ minHeight: '40px', display: 'flex', alignItems: 'center' }}>
            {recipe.name?.slice(0, 35)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}