import * as React from 'react';
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { GetRecipeQuery } from "../../gql/graphql";

interface instructionsListProps {
  instructions: GetRecipeQuery['getRecipe']['instructions'];
}

const InstructionsList = ({ instructions }: instructionsListProps) => {

  const [open, setOpen] = React.useState<boolean>(false);

  const handleClick = () => {
    setOpen(!open);
  }; 

  return (
    <List sx={{ listStyleType: 'disc' }} >
      <ListItemButton onClick={handleClick}>
        <ListItemText primary="Method" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            instructions?.map((instruction, key) => (
              <ListItem sx={{ p: 0, mb: 4, display: 'flex', alignItems: "flex-start" }}>
                <ListItemIcon sx={{pl: 2, pt:1, m: 0}}>
                  {key + 1}
                </ListItemIcon>
                <ListItemText primary={instruction} />
              </ListItem>
            ))
          }
        </List>
      </Collapse>
    </List>
  )
}

export default InstructionsList;