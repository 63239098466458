import * as React from 'react';
import Paper from "@mui/material/Paper";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AddIcon from '@mui/icons-material/Add';
import { useRecipeContext } from '../../providers/RecipeProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import AddRecipeModal from '../AddRecipeModal';

export default function SimpleBottomNavigation() {

  const { authStatus } = useAuthenticator()

  const navigation = useNavigate()

  const location = useLocation();

  const { toggleIsOpen } = useRecipeContext();

  const isAuthenticated = React.useMemo(() => authStatus === 'authenticated', [authStatus]);

  const handleAddClick = React.useCallback(() => {
    if( !isAuthenticated ) {
      navigation(`/auth`)
    } else {
      toggleIsOpen();
    }
  }, [toggleIsOpen, navigation, isAuthenticated]);
  
  if( !isAuthenticated && location.pathname === "/") {
    return null;
  }
  
  return (
    <>
    <AddRecipeModal />
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation
        showLabels
        value={0}
      >
        <BottomNavigationAction color="secondary.dark" label="Add" icon={<AddIcon />} onClick={handleAddClick} />
      </BottomNavigation>
    </Paper>
    </>
  );
}