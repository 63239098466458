import {
  Outlet
} from "react-router-dom";

import Header from '../components/Header';
import { Container } from "@mui/material";

function Root() {
  return (
    <>
      <Header />
      <Container component="main" sx={{minHeight: "105vh", p: 0}} maxWidth="md" >
        <Outlet />
      </Container>
    </>
  )
}

export default Root;