import { Backdrop, CircularProgress } from "@mui/material";

const LoadingScreen = () => (
  <Backdrop
    sx={{ color: '#fff' }}
    open
  >
    <CircularProgress color="secondary" />
  </Backdrop>
)

export default LoadingScreen;