import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import LoadingScreen from "../../components/LoadingScreen";
import { GetRecipeQuery, GetRecipeQueryVariables, MutationUpdateRecipeArgs } from "../../gql/graphql";
import { GET_RECIPE } from "../Recipe/query.graphql";
import { UPDATE_RECIPE } from "./mutation.graphql";
import EditRecipeForm from "../../components/EditRecipeForm/EditRecipeForm";

type FormValues = {
  name: GetRecipeQuery['getRecipe']['name'];
  description: GetRecipeQuery['getRecipe']['description'];
  ingredients?: string[];
  instructions?: { content: string }[];
};

const EditRecipe = () => {

  const { recipeId, username } = useParams();
  const navigation = useNavigate();

  const variables: GetRecipeQueryVariables = {
    recipeId: recipeId as string,
    username: username as string
  };
  
  const { 
    loading, 
    error, 
    data: { 
      getRecipe 
    } = {} } = useQuery<GetRecipeQuery>(GET_RECIPE, {
      variables
    }
  );

  const [updateRecipe, { 
    loading: updateRecipeLoading,
    error: updateRecipeError 
  }] = useMutation(UPDATE_RECIPE, {
    refetchQueries: [
      {
        query: GET_RECIPE,
        variables
      }
    ]
  });


  if( loading || updateRecipeLoading ) {
    return (
      <LoadingScreen />
    );
  }

  if( error || updateRecipeError ) {
    throw error;
  }

  const onSubmit = async (formData: FormValues) => {

    const variables: MutationUpdateRecipeArgs = {
      recipeId: recipeId as string,
      recipe: {
        name: formData.name,
        description: formData.description,
        ingredients: formData?.ingredients,
        instructions: formData?.instructions?.map(({ content }) => content),
      }
    }
    
    await updateRecipe({
      variables
    })

    navigation(`/${username}/${recipeId}`);
  }

  if( loading ) {
    return <LoadingScreen />
  }
  
  return <EditRecipeForm onSubmit={onSubmit} recipe={{
    name: getRecipe?.name,
    description: getRecipe?.description,
    ingredients: getRecipe?.ingredients?.map(ingredient => ingredient.name),
    instructions: getRecipe?.instructions,
  }} />
}

export default EditRecipe;