import { createContext, useContext } from "react";

interface IThemeContext {
  isOpen: boolean;
  toggleIsOpen: () => void;
}

const defaultState = {
  isOpen: false,
  toggleIsOpen: () => null
};

const RecipeContext = createContext<IThemeContext>(defaultState);

export const useRecipeContext = () =>  useContext(RecipeContext);

export default RecipeContext;