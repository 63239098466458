import { useMemo, useState } from "react";
import { Container, Box, CardMedia, Divider, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useMutation, useQuery } from '@apollo/client';
import { Link, useNavigate, useParams } from "react-router-dom";
import { GET_RECIPE } from "./query.graphql";
import { DeleteRecipe, GetAllRecipesQueryVariables, GetRecipeQuery } from "../../gql/graphql";
import IngredientsList from "../../components/IngredientsList";
import InstructionsList from "../../components/InstructionsList";
import LoadingScreen from "../../components/LoadingScreen";
import EditIcon from '@mui/icons-material/Edit';
import TrashIcon from '@mui/icons-material/Delete';
import { useAuthenticator } from "@aws-amplify/ui-react";
import { DELETE_RECIPE } from "./mutation.graphql";
import { GET_ALL_RECIPES } from "../Profile/query.graphql";
import NutritionalInfoTable from "../../components/NutritionalInfoTable";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Recipe = () => {
    
  const [open, setOpen] = useState<boolean>(false);

  const { recipeId, username } = useParams()
  const { user } = useAuthenticator()
  const navigation = useNavigate()

  const canEditRecipe = useMemo(() => {
    return username === user?.username
  }, [username, user])
  
  const { 
    loading, 
    error, 
    data: { 
      getRecipe 
    } = {} } = useQuery<GetRecipeQuery>(GET_RECIPE, {
    variables: {
      recipeId: recipeId as string,
      username: username as string
    }
  });

  const getAllRecipesVariables: GetAllRecipesQueryVariables = {
    username
  }
  
  const [deleteRecipe, { 
    loading: deleteRecipeLoading, 
    error: deleteRecipeError, 
  }] = useMutation<DeleteRecipe>(DELETE_RECIPE, {
    variables: {
      recipeId: recipeId as string
    },
    onCompleted: () => navigation(`/${user.username}`),
    refetchQueries: [
      {
        query: GET_ALL_RECIPES,
        variables: getAllRecipesVariables
      }
    ]
  });

  if( loading || deleteRecipeLoading) {
    return (
      <LoadingScreen />
    );
  }

  if( error || deleteRecipeError ) {
    throw error;
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container sx={{mb: 15, p: 0}}>
      <Box>
        { 
          getRecipe?.image && (
            <CardMedia
              component="img"
              height="240"
              image={getRecipe?.image}
              alt={getRecipe?.name || "alt tag"}
            />
          )
        }
      </Box>
      <Box sx={{p: 2}}>

        <Typography variant="h4" component="div">
          {getRecipe?.name}
        </Typography>

        {
          getRecipe?.link && (
            <Box my={2} width={20}> 
              <Typography 
                display="flex"
                alignItems="center"
                variant="subtitle2" 
                color="primary"
                component="a"
                href={getRecipe?.link}
                target="_blank"
              >
                {new URL(getRecipe?.link).origin}
                <OpenInNewIcon sx={{pl: 1}} />
              </Typography>
            </Box>
          )
        }
        <Typography paragraph variant="body1" component="div">
          {getRecipe?.description}
        </Typography>

        <Divider />

        {
          !!getRecipe?.ingredients?.length && (
            <>
              <IngredientsList ingredients={getRecipe.ingredients}/>
              <Divider />
            </>
          )
        }
        
        {
          !!getRecipe?.instructions?.length && (
            <>
              <InstructionsList instructions={getRecipe.instructions} />
              <Divider />
            </>
          )
        }

{
          !!getRecipe?.nutritionalInfo && (
            <>
              <NutritionalInfoTable nutritionalInfo={getRecipe?.nutritionalInfo} />
              <Divider />
            </>
          )
        }

      {
        !!canEditRecipe && (
          <>
            <Box mt={5}>
              <Button 
                variant="outlined" 
                aria-label="edit recipe" 
                startIcon={<EditIcon />}
                component={Link}
                color="secondary"
                to={`/${username}/${recipeId}/edit`}
                fullWidth
              >
                Edit
              </Button>
            </Box>
            <Box mt={5}>
              <Button 
                variant="outlined" 
                color="warning"
                aria-label="delete recipe" 
                startIcon={<TrashIcon />}
                onClick={() => setOpen(true)}
                fullWidth
              >
                Delete
              </Button>
            </Box>

            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete recipe
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you want to permanently remove this recipe?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={() => deleteRecipe()} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }

      </Box>

    </Container>
  
)}

export default Recipe;