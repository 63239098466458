import { gql } from '@apollo/client';

export const GET_RECIPE = gql`
  query GetRecipe($recipeId: String! $username: String!) {
    getRecipe(recipeId: $recipeId username: $username) {
      id
      link
      description
      ingredients {
        name
        nutritionalInfo {
          name
          calories
          servingSizeG
          fatTotalG
          fatSaturatedG
          proteinG
          sodiumMg
          potassiumMg
          cholesterolMg
          carbohydratesTotalG
          fiberG
          sugarG
        }
      }
      nutritionalInfo {
        calories
        servingSizeG
        fatTotalG
        fatSaturatedG
        proteinG
        sodiumMg
        potassiumMg
        cholesterolMg
        carbohydratesTotalG
        fiberG
        sugarG
      }
      instructions
      image
      name
      keywords
      rating
      createdAt
      updatedAt
    }
  }
`;