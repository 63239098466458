import { useAuthenticator } from '@aws-amplify/ui-react';
import { useMutation, useLazyQuery } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import { CREATE_RECIPE, SCRAPE_URL } from "./mutation.graphql";
import { useRecipeContext } from "../../providers/RecipeProvider";
import { GET_ALL_RECIPES } from "../../pages/Home/query.graphql";
import LoadingScreen from '../LoadingScreen';
import { useCallback, useMemo } from 'react';
import EditRecipeForm from '../EditRecipeForm';
import { CloseSharp } from "@mui/icons-material";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import { CreateRecipeMutation, CreateRecipeMutationVariables, ScrapeRecipeQuery, ScrapeRecipeQueryVariables } from '../../gql/graphql';

type FormValues = {
  recipeUrl: string;
};

const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

const AddRecipeModal = () => {
  const { user } = useAuthenticator();

  const { isOpen, toggleIsOpen } = useRecipeContext();

  const [scrapeUrl, {
    data,
    loading,
    error
  }] = useLazyQuery<ScrapeRecipeQuery, ScrapeRecipeQueryVariables>(SCRAPE_URL)

  const recipe = useMemo(() => {
    return {
      name: data?.scrapeRecipe.name,
      description: data?.scrapeRecipe.description,
      ingredients: data?.scrapeRecipe.ingredients?.map(ingredient => ingredient.name),
      instructions: data?.scrapeRecipe.instructions?.filter(Boolean),
    }
  }, [data])

  const [createRecipe, {
    loading: createRecipeLoading,
    client: {
      refetchQueries
    }
  }] = useMutation<CreateRecipeMutation, CreateRecipeMutationVariables>(CREATE_RECIPE, {
    refetchQueries: () => [{
      query: GET_ALL_RECIPES,
      variables: { 
        username: user.username
      }
    }]
  });

  const { control, handleSubmit, formState: { errors }, setValue, getValues } = useForm<FormValues>({
    defaultValues: {
      recipeUrl: ""
    }
  });

  const pasteClipboardContent = useCallback(async () => {
    const text = await navigator.clipboard.readText();
    setValue("recipeUrl", text)

    const isValidUrl = urlRegex.test(text);

    if (isValidUrl) {
      scrapeUrl({
        variables: {
          url: text
        }
      })
    }

  }, [setValue, scrapeUrl])

  const onSubmit = async (formData: FormValues) => {
    if( errors.recipeUrl ) {
      return
    }

    await scrapeUrl({
      variables: {
        url: formData.recipeUrl
      }
    })
    
  };

  const saveRecipe = useCallback(async (formData: {
    name: string;
    description: string;
    ingredients: string[];
    instructions: { content: string }[];
    recipeUrl: string;
  }) => {
    await createRecipe({
      variables: {
        recipe: {
          link: getValues("recipeUrl"),
          name: formData.name,
          description: formData.description,
          ingredients: formData.ingredients,
          instructions: formData.instructions.map(({content}) => content),
        }
      }
    })
    toggleIsOpen()
    refetchQueries({
      include: [GET_ALL_RECIPES]
    });
  }, [createRecipe, getValues, refetchQueries, toggleIsOpen])

  if( createRecipeLoading ) {
    return <LoadingScreen />
  }

  if( error ) {
    throw error;
  }

  return (
    <Dialog onClose={toggleIsOpen} open={isOpen} fullWidth fullScreen>
      <Box>
        {/* <CardHeader title="New recipe" sx={{ bgcolor: "primary.dark", color: "white"}} /> */}


        <Box sx={{ bgcolor: "primary.dark"}} 
          p={2} 
        >
          <Typography 
            color="white" 
            variant="h6"
            display="flex" 
            justifyContent="space-between" 
            flexDirection="row"
            alignItems="center"
          >
            New recipe
            <CloseSharp onClick={toggleIsOpen} />
          </Typography>
        </Box>
        
        <Box
          px={2}
          mb={-2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={3}
          sx={{
            '& > :not(style)': { mt: 1 },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            sx={{
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Controller
              name="recipeUrl"
              control={control}
              rules={{
                required: true,
                pattern: /^(http|https):\/\/[^ "]+$/
              }}
              render={({ field }) => (
                <TextField
                  required
                  error={!!errors.recipeUrl}
                  helperText={!!errors.recipeUrl && "Invalid URL"}
                  id="recipeUrl" 
                  label="Recipe URL" 
                  variant="standard" 
                  sx={{
                    // width: "100%",
                    flex: 1,
                    whiteSpace: 'nowrap',
                    marginBottom: 2
                  }} 
                  {...field}
                />
              )}
            />
            <Button
              onClick={pasteClipboardContent}
              sx={{
                justifyContent: "flex-end"
              }}
            >
              <ContentPasteGoIcon />
            </Button>
          </Box>
        </Box>

        <EditRecipeForm onSubmit={saveRecipe} recipe={recipe} loading={loading} />
      </Box>
    </Dialog>
  )
}

export default AddRecipeModal;