import { gql } from "@apollo/client";

export const UPDATE_RECIPE = gql`
  mutation UpdateRecipe($recipeId: String!, $recipe: CreateRecipe!) {
    updateRecipe(recipeId: $recipeId, recipe: $recipe) {
      name
      description
      ingredients {
        name
        nutritionalInfo {
          name
          calories
          servingSizeG
          fatTotalG
          fatSaturatedG
          proteinG
          sodiumMg
          potassiumMg
          cholesterolMg
          carbohydratesTotalG
          fiberG
          sugarG
        }
      }
      instructions
    }
  }
`