import { gql } from '@apollo/client';

export const GET_ALL_RECIPES = gql`
  query GetAllRecipes($username: String) {
    getAllRecipes(username: $username) {
      id
      link
      image
      username
      name
      description
      keywords
      rating
      createdAt
      updatedAt
    }
  }
`;