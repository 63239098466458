import { gql } from '@apollo/client';

export const CREATE_RECIPE = gql`
  mutation CreateRecipe($recipe: CreateRecipe!) {
    createRecipe(recipe: $recipe) {
      id
      name
      link
    }
  }
`;

export const SCRAPE_URL = gql`
  query ScrapeRecipe($url: String!) {
    scrapeRecipe(url: $url) {
      id
      link
      image
      username
      name
      description
      ingredients {
        name
      }
      instructions
      keywords
      rating
      createdAt
      updatedAt
    }
  }
`