import { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Box, Button, Chip, Divider, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemIcon, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/AddCircle';
import { GetRecipeQuery } from "../../gql/graphql";
import SaveIcon from '@mui/icons-material/Save';
import RemoveIcon from '@mui/icons-material/Close';

type Recipe = {
  name: GetRecipeQuery['getRecipe']['name'];
  description: GetRecipeQuery['getRecipe']['description'];
  ingredients?: string[];
  instructions?: string[] | null;
};

type FormValues = {
  name: GetRecipeQuery['getRecipe']['name'];
  description: GetRecipeQuery['getRecipe']['description'];
  ingredients?: string[];
  instructions?: { content: string }[];
};

const EditRecipeForm = ({
  recipe,
  onSubmit,
  loading
}: {
  recipe?: Recipe | null
  onSubmit: any;
  loading?: boolean
}) => {

  const [ingredient, setIngredient] = useState<string|undefined>();

  
  const { 
    setValue, 
    getValues, 
    handleSubmit, 
    watch, 
    control,
    register
  } = useForm<FormValues>({
    defaultValues: {
      name: recipe?.name ?? "",
      description: recipe?.description ?? "",
      ingredients: recipe?.ingredients,
      instructions: recipe?.instructions?.map(instruction => ({content: instruction})),
    }
  });

  watch('ingredients');
  watch('instructions');

  const { fields, append, remove,  } = useFieldArray({
    name: "instructions",
    control
  });
  
  useEffect(() => {
    setValue('name', recipe?.name)
    setValue('description', recipe?.description)
    setValue('ingredients', recipe?.ingredients)
    // setValue('instructions', recipe?.instructions)
  }, [recipe, setValue])

  const ingredients = getValues('ingredients')
  
  const handleDelete = (ingredient: string) => {

    const ingredients = getValues('ingredients');

    const newListOfIngredients = ingredients?.filter((ingredientName) => 
      ingredientName !== ingredient
    );

    setValue('ingredients', newListOfIngredients);
  }


  const addIngredient = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const ingredients = ingredient?.split(',')
    
    const existingIngredients = getValues('ingredients') || [];
    
    if( ingredients?.length ) {
      setValue('ingredients', [...existingIngredients, ...ingredients]);
    }

    setIngredient("")
  }
  
  return (
    <>
      <form id="ingredient-form" onSubmit={addIngredient}></form>
      <List
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        id="recipe-form"
        sx={{
          p: 2,
          '& .MuiTextField-root': { my: 2 },
        }}
        noValidate
        autoComplete="off"
      >
        <Controller
          name="name"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => (
            <TextField 
              fullWidth
              id="recipe-name" 
              label="Recipe name" 
              variant="standard" 
              {...field}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => (
            <TextField 
              fullWidth
              id="recipe-description" 
              label="Recipe description" 
              variant="standard"
              multiline
              rows={4}
              maxRows={4}
              {...field}
            />
          )}
        />

        <Box>
          <TextField 
            fullWidth
            id="ingredient" 
            label="Ingredients" 
            variant="standard"
            helperText="Use commas to enter multiple at once"
            value={ingredient}
            onChange={e => setIngredient(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{mr: 1, mb: 1}}>
                  <IconButton form="ingredient-form" edge="end" color="primary" type="submit">
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {
            ingredients?.map((ingredient) => (
              <Chip key={ingredient} sx={{my: 1, mr: 1}} label={ingredient} onDelete={() => handleDelete(ingredient)} size="small" />
            ))
          }
        </Box>

        <Box mt={4}>
          <List
            sx={{ width: '100%', bgcolor: 'background.paper', p: 0, m: 0 }}
          >
            <ListItem
              sx={{ width: '100%', p: 1 }}
            >Instructions</ListItem>
            {fields.map((field, index) => (
              <ListItem 
                key={field.id} 
                sx={{ width: '100%', p: 1 }}
              >
                <Controller
                  control={control}
                  rules={{
                    required: true
                  }}
                  {...register(`instructions.${index}.content`)} 
                  render={({ field: controlledField }) => (
                    <TextField 
                      fullWidth
                      variant="standard" 
                      multiline
                      defaultValue={field.content}
                      {...controlledField}
                    />
                  )}
                  />
                <ListItemButton onClick={() => remove(index)} sx={{p: 0, minWidth: '25px'}}>
                  <ListItemIcon>
                    <RemoveIcon />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem sx={{display:'flex', justifyContent:'flex-end', mb: 5}}>
              <Button variant="text" onClick={() => append({ content: ""})}>
                Add step
              </Button>
            </ListItem>
            <Divider />
          </List> 
        </Box> 

        <Box mt={5} mx={2}>
          <Button
            variant="contained" 
            aria-label="Save changes" 
            startIcon={<SaveIcon />}
            fullWidth
            type="submit"
            form="recipe-form"
          >
            Save changes
          </Button>
        </Box>

      </List>
    </>
  );
}

export default EditRecipeForm;